.header {
  background-color: $color-white !important;
}

.download {
  background-color: $color-off-white !important;
  padding: 0 10% !important;

  &__heading {
    font-size: 20px;
    font-weight: 600;
    color: $color-primary;
  }

  &__group-img {
    width: 100%;
  }
}

.footer {
  background-color: $color-primary !important;

  &__text {
    font-size: 14px;
    color: $color-white;
    padding: 15px;
  }
}
