#start-class-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-view {
  position: relative;
  margin-top: 64px;
  height: calc(100vh - 64px - 64px);
  background-color: #222222;

  @include respond(tab-port) {
    margin-top: 56px;
    height: calc(100vh - 56px - 56px);
  }
}
