.add-batch-here {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
}

.add-batch {
  h1 {
    font-size: 40px;
    color: #3f51b5;
    margin-bottom: 30px;
  }

  label {
    font-size: 20px;
    color: #3f51b5;
    margin-bottom: 30px;
  }
}
