@import 'Assets/Scss/main.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

body {
  background-color: #f2f5ff;
  color: #333;
  overflow-x: hidden;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-1 {
  flex: 1;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;

  @include respond(tab-port) {
    padding: 1rem;
  }
}

.form-control {
  margin: 0.5rem auto;
}

.form-control-label {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
  color: #333333;
}

.mobile {
  display: none;

  @include respond(ipad-pro) {
    display: block;
  }
}

.desktop {
  @include respond(ipad-pro) {
    display: none;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.excalidraw-modal-container {
  z-index: 2001 !important;
}

.excalidraw .ToolIcon__library {
  display: none !important;
}

.excalidraw .help-icon {
  display: none !important;
}

.react-time-picker__inputGroup {
  padding: 10px;
}

.react-time-picker {
  width: 300px;

  .react-time-picker__wrapper {
    width: 0px;
    border-radius: 5px;
    border: 1px solid #999;
    padding: 10px;
  }
}
