.showcase__container {
  background-color: #3458a1;
  position: relative;
  padding-bottom: 50px;
}

.showcase__content {
  width: 86%;
  margin: auto;
  padding-top: 6%;
}

.showcase__bgImg {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  overflow: hidden;
}

.showcase__header {
  font-size: 54px;
  color: #fff;
  padding: 0;
  margin: 0;
}

.showcase__para {
  font-size: 32px;
  color: #f7c66b;
  margin: 0;
  margin-top: 13px;
  font-family: Rubik;
  font-weight: 300;
}

.content__container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 18px;
  margin-left: 18px;
  margin-right: 18px;
}

.content_container_faq {
  width: 60%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
}

.content_container_other {
  width: 40%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
}

.separator {
  width: 10px;
  margin: 8px 8px;
}

.other_header_container {
  margin-top: 34px;
  margin-bottom: 34px;
}

.footer__link__header {
  margin-top: 34px;
}

.footer__top__right {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer__link {
  padding-top: 0;
  color: #666;
}

.para,
.link {
  font-family: Rubik;
  color: #666;
  font-size: 18px;
  margin: 0;
}

@media (max-width: 820px) {
  .showcase__bgImg {
    display: none;
  }

  .showcase__header {
    font-size: 32px;
  }

  .showcase__container {
    padding-bottom: 30px;
  }

  .content__container {
    margin-top: 30px;
    flex-direction: column;
  }

  .content_container_faq {
    width: 100%;
    margin-top: 20px;
  }

  .content_container_other {
    width: 100%;
    margin-top: 0px;
  }
}
