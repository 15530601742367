@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 400px) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint == ipad-pro {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == lap-small {
    @media (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
