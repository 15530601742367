.refresh {
  position: fixed;
  width: 35%;
  background-color: #606060;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0;
  right: 10px;

  @include respond(lap-small) {
    width: 45%;
  }

  @include respond(tab-land) {
    width: 65%;
  }

  @include respond(tab-port) {
    display: none;
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 10px;

    &__text {
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0 10px;
      color: #fff;
    }
  }
}
